import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import SEO from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import { hex2rgba } from "../../styles/tools"
import Row from "../../styles/Row"

import { useTranslateValue } from "../../data/TranslateContext"
import FrenchOurStory from "./FrenchOurStory"
import EnglishOurStory from "./EnglishOurStory"

const OurStoryPageWrapper = styled(PageWrapper)`
  padding-top: 0;

  color: ${props => props.theme.color.primary};
`
const NarrowRow = styled(Row)`
  margin-bottom: 3rem;
  max-width: 900px;

  text-align: center;

  a {
    text-decoration: underline;
  }

  .gatsby-image-wrapper {
    margin: 0 0 2rem;

    box-shadow: 0 2px 12px ${props => hex2rgba(props.theme.color.primary, 0.2)};

    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
  }
`

export default function OurStoryPage() {
  const [{ language }] = useTranslateValue()

  const images = useStaticQuery(graphql`
    query ourStoryImages {
      header: file(relativePath: { eq: "our-story-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, traceSVG: { color: "#004E7D" }) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <OurStoryPageWrapper>
      <SEO title="Our Story" />
      <NarrowRow>
        <Img fluid={images.header.childImageSharp.fluid} />

        {language === "en" && <EnglishOurStory />}
        {language === "fr" && <FrenchOurStory />}
      </NarrowRow>
    </OurStoryPageWrapper>
  )
}
